import type { ProjectConfigInterface } from '@ir-engine/projects/ProjectConfigInterface'

const config: ProjectConfigInterface = {
  onEvent: undefined,
  routes: {},
  services: undefined,
  worldInjection: () => import('./worldInjection')
}

export default config
