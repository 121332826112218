import LoadingView from '@ir-engine/ui/src/primitives/tailwind/LoadingView'
import { t } from 'i18next'
import React from 'react'

export const LocationLoadingUI = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-white" style={{ zIndex: 1000000 }}>
      <LoadingView
        fullScreen
        className="block h-12 w-12"
        title={t('common:loader.loadingApp')}
        titleClassname="text-[#262626]"
      />
    </div>
  )
}
